import { MemberDoc, useOverviewContext } from "../../contexts/OverviewContext";
import { auth, db } from "../../utils/firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import { AiFillEdit } from "react-icons/ai";
import { BiExit } from "react-icons/bi";
import { FaUserAstronaut } from "react-icons/fa";
import Layout from "../../components/Layout";
import ProfileCard from "../../components/ProfileCard";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../../contexts/ProjectContext";

function Profile() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const overviewContext = useOverviewContext();
  const [editMode, setEditMode] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [phone, setPhone] = useState("");
  const projectContext = useProjectContext();

  useEffect(() => {
    if (overviewContext.loading) return;
    if (!user) return navigate("/");
  }, [user, loading, navigate, overviewContext.loading]);

  useEffect(() => {
    if (projectContext && !projectContext.lastSelectedProject) {
      navigate("/selectProject");
    }
  });

  const onSignOut = () => {
    signOut(auth).then(() => navigate("/"));
  };

  if (loading || overviewContext.loading || !overviewContext.team) return <></>;

  const getCurrentUserFromMembers = (m: MemberDoc[]) => {
    if (user) {
      return m.find((x) => x.email === user!.email);
    }
  };

  const currentUser = getCurrentUserFromMembers(overviewContext.team.members);

  const onAttemptEdit = () => {
    setDisplayName(currentUser?.displayName ? currentUser?.displayName : "");
    setPhone(currentUser?.phone ? currentUser?.phone : "");
    setEditMode(true);
  };

  const onSaveProfile = async () => {
    if (!projectContext) return;
    const docRef = doc(
      db,
      `prod/${projectContext.lastSelectedProject}/teams/${overviewContext.team?.teamName}`
    );
    const newMembers = overviewContext!.team!.members.map((m) => {
      if (m.email === user?.email) {
        return {
          ...m,
          displayName,
          phone,
        };
      } else {
        return m;
      }
    });
    await updateDoc(docRef, {
      members: newMembers,
    });
    setEditMode(false);
  };

  return (
    <Layout title="My Team Members" currentTabIndex={2}>
      <div className="px-4 h-full flex flex-col">
        {user && (
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row items-center space-x-2">
              <div className="w-16 h-16 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 flex justify-center items-center rounded-full">
                <FaUserAstronaut className="text-4xl text-white" />
              </div>
              <div>
                <div className="flex flex-row w-48 space-x-2">
                  <p className="text-2xl truncate">
                    {currentUser
                      ? currentUser.displayName
                        ? currentUser.displayName
                        : currentUser.name
                      : "..."}
                  </p>
                  <button
                    className="p-2 rounded-full bg-blue-500 text-white"
                    onClick={onAttemptEdit}
                  >
                    <AiFillEdit />
                  </button>
                </div>
                <p className="underline">{user.email}</p>
              </div>
            </div>

            <div className="">
              <button
                className="bg-red-500 py-2 pl-2 pr-4 rounded-full text-white flex flex-row items-center"
                onClick={onSignOut}
              >
                <BiExit className="mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        )}
        {/* other members */}
        {!editMode && (
          <div className="grow my-4 overflow-y-scroll h-0 space-y-2">
            {overviewContext.team.members
              .filter((v) => v.email !== user?.email)
              .map((value, index) => {
                return (
                  <ProfileCard
                    key={index}
                    name={value.displayName ? value.displayName : value.name}
                    email={value.email}
                    phone={value.phone}
                  />
                );
              })}
          </div>
        )}
        {editMode && (
          <div className="my-4 overflow-y-scroll space-y-4">
            <p className="text-lg bold underline">Edit Profile</p>
            <div>
              <p>Preferred Name</p>
              <input
                className="w-full border-2 rounded p-2"
                type="text"
                placeholder="Preferred Name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </div>
            <div>
              <p>Phone</p>
              <input
                className="w-full border-2 rounded p-2"
                type="text"
                placeholder="e.g., 9491234567"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            {/* <div>
              <p>Pronouns</p>
              <select className="w-full">
                <option value="he/him">He/Him</option>
                <option value="she/her">She/Her</option>
                <option value="they/them">They/Them</option>
              </select>
            </div> */}
            <div>
              <button
                className="bg-blue-500 text-white w-full h-10"
                onClick={onSaveProfile}
              >
                Save
              </button>
            </div>
          </div>
        )}
        <div className="py-2">
          <p className="text-center text-gray-300">Contact Us</p>
          <p className="text-center text-gray-300">Yu Lu | ylu31@uci.edu</p>
        </div>
      </div>
    </Layout>
  );
}

export default Profile;
