import dgreen from "../assets/faces/dgreen.png";
import green from "../assets/faces/green.png";
import orange from "../assets/faces/orange.png";
import red from "../assets/faces/red.png";
import yellow from "../assets/faces/yellow.png";

type Smirk = {
  file: string;
  color: string;
  description: string;
};

export const Smirks: Smirk[] = [
  {
    file: dgreen,
    color: "#a8d453",
    description: "Did something extraordinary",
  },
  { file: green, color: "#c4db6b", description: "Went above and beyond" },
  { file: yellow, color: "#f6d567", description: "Contributed as expected" },
  { file: orange, color: "#df8270", description: "Did less than needed" },
  { file: red, color: "#eb6145", description: "Was not helpful at all" },
];
