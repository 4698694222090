import React, { ReactNode } from "react";
import { auth, db } from "../utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";
import { useProjectContext } from "./ProjectContext";

export type OverviewDoc = {
  name: string;
  email: string;
  team: string;
  f0: number;
  f1: number;
  f2: number;
  f3: number;
  f4: number;
};

export type MemberDoc = {
  email: string;
  name: string;
  displayName?: string;
  phone?: string;
};

export type TeamDoc = {
  teamName: string;
  members: MemberDoc[];
  responseCount: number;
};

export type OverviewContextType = {
  loading: boolean;
  overview: OverviewDoc | null;
  team: TeamDoc | null;
  smirks: number[];
};

const OverviewContext = React.createContext<OverviewContextType>({
  loading: true,
  overview: null,
  team: null,
  smirks: [0, 0, 0, 0, 0],
});

const OverviewContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user] = useAuthState(auth);
  const [loading, setLoading] = React.useState(true);
  const [overview, setOverview] = React.useState<OverviewDoc | null>(null);
  const [team, setTeam] = React.useState<TeamDoc | null>(null);
  const [smirks, setSmirks] = React.useState([0, 0, 0, 0, 0]);
  const projectContext = useProjectContext();

  React.useEffect(() => {
    if (
      user &&
      user.email &&
      projectContext &&
      projectContext.lastSelectedProject
    ) {
      const unsub = onSnapshot(
        doc(
          db,
          "prod",
          projectContext.lastSelectedProject,
          "overview",
          user!.email!
        ),
        (doc) => {
          if (doc.exists()) {
            const data = doc.data() as OverviewDoc;
            setOverview(data);
            if (data !== undefined) {
              setSmirks([data.f0, data.f1, data.f2, data.f3, data.f4]);
            }
          }
        }
      );
      return unsub;
    }
  }, [user, projectContext]);

  React.useEffect(() => {
    if (overview && projectContext && projectContext.lastSelectedProject) {
      const unsub = onSnapshot(
        doc(
          db,
          "prod",
          projectContext.lastSelectedProject,
          "teams",
          overview.team
        ),
        (doc) => {
          if (doc.exists()) {
            const data = doc.data() as TeamDoc;
            setTeam(data);
          }
        }
      );
      return unsub;
    }
  }, [overview, projectContext]);

  React.useEffect(() => {
    if (overview && team && smirks) {
      setLoading(false);
    }
  }, [overview, team, smirks]);

  return (
    <OverviewContext.Provider value={{ loading, overview, team, smirks }}>
      {children}
    </OverviewContext.Provider>
  );
};

const useOverviewContext = () => {
  const context = React.useContext(OverviewContext);
  return context;
};

export { OverviewContextProvider, useOverviewContext };
