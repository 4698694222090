import React from "react";
import { Smirks } from "./Smirks";

type BarChartProps = {
  smilies: number[];
};

const BarChart: React.FC<BarChartProps> = ({ smilies }) => {
  const maxP = 100;
  const minP = 5;

  // const maxSmirk = Math.max(...smilies);
  const maxSmirk = 15;

  return (
    <div className="w-full h-full flex flex-row justify-around">
      {smilies.map((value, index) => {
        let percentage = "0%";
        if (value <= 0) {
          percentage = `${minP}%`;
        } else if (value > maxSmirk) {
          percentage = `${maxP}%`;
        } else {
          percentage = `${
            Math.floor(((maxP - minP) / maxSmirk) * value) + minP
          }%`;
        }

        return (
          <div
            className="w-12 h-full flex flex-col items-center justify-end"
            key={index}
          >
            <div>
              <p
                className="text-lg font-bold"
                style={{ color: Smirks[index].color }}
              >
                {value}
              </p>
            </div>
            <div
              className="w-full rounded-t-xl shadow transition-all duration-500"
              style={{
                height: percentage,
                backgroundColor: Smirks[index].color,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BarChart;
