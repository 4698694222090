import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/firebase";
import { doc, getDoc } from "firebase/firestore";

import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FirebaseError } from "firebase/app";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { delay } from "../../utils";
import { useAuthState } from "react-firebase-hooks/auth";

// TODO: Refactor Sign Up page

function SignUp() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const [attempted, setAttempted] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [loading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    pwd: "",
    cpwd: "",
  });

  useEffect(() => {
    if (user) return navigate("/home");
  }, [user, navigate]);

  const cleanUpErrors = () => {
    setEmailError("");
    setPasswordError("");
    setConfirmError("");
    setSignUpError("");
  };

  const onSignUp = async () => {
    setIsLoading(true);
    setAttempted(true);
    cleanUpErrors();

    await delay(500);

    // email not empty
    if (formData.email === "") {
      setEmailError("Email cannot be empty!");
    } else if (formData.pwd === "") {
      setPasswordError("Password cannot be empty");
    } else if (formData.pwd.length < 6) {
      setPasswordError("Password must be at least 6 characters");
    } else if (formData.cpwd !== formData.pwd) {
      setConfirmError("Password mismatch!");
    } else {
      const docSnap = await getDoc(
        doc(db, "/prod/_allow_list/emails", formData.email.toLowerCase())
      );
      if (!docSnap.exists()) {
        setEmailError("Email not in allow list!");
      } else {
        try {
          await createUserWithEmailAndPassword(
            auth,
            formData.email.toLowerCase(),
            formData.pwd
          );
          navigate("/signin");
        } catch (e) {
          if (
            e instanceof FirebaseError &&
            e.code === "auth/email-already-in-use"
          ) {
            setSignUpError("Email already used!");
          } else {
            setSignUpError("Something went wrong!");
          }
        }
      }
    }
    setIsLoading(false);
  };

  const onFormDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAttempted(false);
    cleanUpErrors();
    setFormData((data) => {
      return { ...data, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="h-full flex justify-center items-center">
      <div className="border-2 p-4 w-full m-4">
        <div className="mb-4">
          <h1 className="text-3xl">Sign Up</h1>
        </div>
        <div className="space-y-4">
          <div>
            <div className="flex flex-col">
              <label>Email</label>
              <input
                className="border-2 border-blue-500 p-2 rounded"
                name="email"
                value={formData.email}
                onChange={onFormDataChange}
                placeholder="UCI email address"
              />
              {attempted && emailError !== "" ? (
                <p className="text-red-500">{emailError}</p>
              ) : (
                <p className="invisible">Email error</p>
              )}
            </div>
            <div className="flex flex-col">
              <label>Password</label>
              <input
                className="border-2 border-blue-500 p-2 rounded"
                type="password"
                value={formData.pwd}
                name="pwd"
                placeholder="at least 6 characters"
                onChange={onFormDataChange}
              />
              {attempted && passwordError !== "" ? (
                <p className="text-red-500">{passwordError}</p>
              ) : (
                <p className="invisible">Email error</p>
              )}
            </div>
            <div className="flex flex-col">
              <label>Confirm Password</label>
              <input
                className="border-2 border-blue-500 p-2 rounded"
                name="cpwd"
                type="password"
                value={formData.cpwd}
                onChange={onFormDataChange}
              />
              {attempted && confirmError !== "" ? (
                <p className="text-red-500">{confirmError}</p>
              ) : (
                <p className="invisible">Confirm Error</p>
              )}
            </div>
          </div>
          <div>
            {loading ? (
              <button className="w-full bg-gray-500 h-16 text-white p-4 flex flex-row justify-center items-center">
                <AiOutlineLoading3Quarters className="animate-spin" />
              </button>
            ) : (
              <button
                className="w-full bg-blue-500 h-16 text-white p-4"
                onClick={onSignUp}
              >
                Sign Up
              </button>
            )}
            {attempted && signUpError !== "" ? (
              <p className="text-red-500">{signUpError}</p>
            ) : (
              <p className="invisible">Sign up error</p>
            )}
          </div>
          <div>
            <Link to="/" className="text-blue-500 underline">
              Back to main page
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
