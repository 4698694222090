import React, { useEffect } from "react";

import { auth } from "../utils/firebase";
import team from "../assets/team.jpg";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

function Main() {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (user) return navigate("/home");
  }, [user, loading, navigate]);

  const onSignUp = () => {
    navigate("/signup");
  };
  const onSignIn = () => {
    navigate("/signin");
  };

  if (user) return <></>;

  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-col justify-center grow">
        <div className="flex flex-col items-center space-y-4">
          <h1 className="text-4xl font-bold">Daily Smirk</h1>
          <p className="text-xl italic">Continuous Peer Assessment</p>
          <img className="w-full max-w-xl" src={team} alt="Team" />
        </div>
      </div>
      <div className="flex flex-col p-4 space-y-2">
        <button
          className="border-2 border-blue-500 text-blue-500 p-4"
          onClick={onSignUp}
        >
          No account? Sign Up!
        </button>
        <button className="bg-blue-500 text-white p-4" onClick={onSignIn}>
          Sign In
        </button>
      </div>
    </div>
  );
}

export default Main;
