import React, { useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";

import { auth } from "../../utils/firebase";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

function UnSupported() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) return navigate("/");
  }, [user, navigate]);

  const onSignOut = () => {
    console.log(1);
    signOut(auth);
    redirect("/");
  };
  return (
    <div className="h-full w-full flex justify-center items-center flex-col text-center space-y-4">
      <p className="text-3xl">You are not enrolled in any group project.</p>

      {user?.email && (
        <p className="">
          We cannot find your account ({user?.email}) in our roster.
        </p>
      )}

      <p className="">
        If you believe this is an error, please contact ylu31@uci.edu
      </p>
      <button
        className="bg-red-500 text-white px-4 py-2 rounded-full"
        onClick={onSignOut}
      >
        Sign Out
      </button>
    </div>
  );
}

export default UnSupported;
