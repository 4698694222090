import { MdOutlineEmail, MdOutlinePhone, MdPerson } from "react-icons/md";

import React from "react";

type ProfileCardProps = {
  name: string;
  email: string;
  phone?: string;
};

const ProfileCard: React.FC<ProfileCardProps> = ({ name, email, phone }) => {
  return (
    <div className="border p-2">
      <div className="flex flex-row space-x-2 items-center">
        <MdPerson className="text-blue-500 text-xl" />
        <p className="text-xl truncate">{name}</p>
      </div>

      <div className="flex flex-row space-x-2 items-center py-1">
        <MdOutlineEmail className="text-purple-500 text-xl" />
        <p className="underline">{email}</p>
      </div>
      {phone && (
        <div className="flex flex-row space-x-2 items-center py-1">
          <MdOutlinePhone className="text-purple-500 text-xl" />
          <p className="underline">{phone}</p>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;
