import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useEffect, useState } from "react";

import Layout from "../../components/Layout";
import ReceivedSmirks from "../../components/ReceivedSmirks";
import ReviewedSmirks from "../../components/ReviewedSmirks";
import { auth } from "../../utils/firebase";
import moment from "moment";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useProjectContext } from "../../contexts/ProjectContext";

function Calendar() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user === null) return navigate("/");
  }, [user, navigate]);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [mode, setMode] = useState<"received" | "reviewed">("received");
  const [shouldDisplay, setShouldDisplay] = useState(true);
  const projectContext = useProjectContext();

  useEffect(() => {
    if (projectContext && !projectContext.lastSelectedProject) {
      navigate("/selectProject");
    }
  });

  const ReceivedButton = () => {
    return (
      <button
        className={
          `border border-blue-500 p-1 rounded-l px-2` +
          (mode === "received" ? " bg-blue-500 text-white" : " text-blue-500")
        }
        onClick={() => {
          setMode("received");
          setShouldDisplay(true);
        }}
      >
        Received
      </button>
    );
  };

  const ReviewedButton = () => {
    return (
      <button
        className={
          `border border-blue-500 p-1 rounded-r px-2` +
          (mode === "reviewed" ? " bg-blue-500 text-white" : " text-blue-500")
        }
        onClick={() => {
          setMode("reviewed");
          setShouldDisplay(true);
        }}
      >
        Reviewed
      </button>
    );
  };

  const onChangeMonth = (delta: number) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + delta);
    setCurrentDate(newDate);
  };

  const shouldDisableRight = () => {
    const today = new Date();
    return (
      currentDate.getFullYear() >= today.getFullYear() &&
      currentDate.getMonth() >= today.getMonth()
    );
  };
  return (
    <Layout title={"Daily Ratings of You"} currentTabIndex={1}>
      <div className="h-full px-4 pb-4 flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-4">
            {shouldDisplay && (
              <button onClick={() => onChangeMonth(-1)}>
                <BsChevronLeft />
              </button>
            )}
            <h2 className="text-2xl font-bold">
              {moment().month(currentDate.getMonth()).format("MMMM")}
            </h2>
            {shouldDisplay && !shouldDisableRight() && (
              <button onClick={() => onChangeMonth(+1)}>
                <BsChevronRight />
              </button>
            )}
          </div>
          <div className="flex items-center">
            <ReceivedButton />
            <ReviewedButton />
          </div>
        </div>
        <div className="flex-1">
          {mode === "received" ? (
            <ReceivedSmirks
              date={currentDate}
              setShouldDisplay={setShouldDisplay}
            />
          ) : (
            <ReviewedSmirks date={currentDate} />
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Calendar;
