import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useEffect } from "react";
function Loading() {
  useEffect(() => {});
  return (
    <div className="h-full flex justify-center items-center animate-spin">
      <AiOutlineLoading3Quarters className="text-4xl" />
    </div>
  );
}

export default Loading;
