import { Smirks } from "../../components/Smirks";

type Props = {
  name: string;
  rating: number;
};

function SurveyIntro({ name, rating }: Props) {
  return (
    <div className="space-y-1">
      {/* Description */}
      <p className="indent-4">
        You ranked <span className="font-bold underline">{name}</span> as "
        <span
          style={{ color: Smirks[rating].color }}
          className="font-bold capitalize"
        >
          {Smirks[rating].description}
        </span>
        ". The instructor would like to learn a little bit more why you ranked{" "}
        <span className="font-bold underline">{name}</span> as such, so please
        provide your thoughts below.
      </p>
      <p className="indent-4">
        You can mark as many or as few of the reasons provided, and there is
        also room to provide additional feedback.{" "}
        <span className="underline">
          All answers are anonymous to your peers.
        </span>
      </p>
    </div>
  );
}

export default SurveyIntro;
