import { AiFillCalendar, AiOutlineCalendar } from "react-icons/ai";
import {
  RiHome2Fill,
  RiHome2Line,
  RiTeamFill,
  RiTeamLine,
} from "react-icons/ri";

import React from "react";
import { useNavigate } from "react-router-dom";

export const AvailableTabs = {
  home: {
    selected: RiHome2Fill,
    unselected: RiHome2Line,
    path: "/home",
    name: "Home",
    header: "Daily Smirk",
  },
  calendar: {
    selected: AiFillCalendar,
    unselected: AiOutlineCalendar,
    path: "/calendar",
    name: "Calendar",
    header: "My Ratings",
  },
  profile: {
    selected: RiTeamFill,
    unselected: RiTeamLine,
    path: "/profile",
    name: "Team",
    header: "Profile",
  },
};

export const AppTabs = [
  AvailableTabs.home,
  AvailableTabs.calendar,
  AvailableTabs.profile,
];

type TabsProps = {
  currentIndex: number;
};

const Tabs: React.FC<TabsProps> = ({ currentIndex }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-row justify-around py-2 border-t-2 border-bray-500">
      {AppTabs.map((value, index) => {
        return (
          <div
            key={index}
            className="flex flex-col items-center cursor-pointer rounded"
            onClick={() => {
              navigate(value.path);
            }}
          >
            {index === currentIndex ? (
              <>
                <value.selected className="text-2xl text-blue-500" />
                <p className="text-xs text-blue-500 cursor-pointer">
                  {value.name}
                </p>
              </>
            ) : (
              <>
                <value.unselected className="text-2xl text-gray-500" />
                <p className="text-xs text-gray-500 cursor-pointer">
                  {value.name}
                </p>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Tabs;
