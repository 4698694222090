// Import the functions you need from the SDKs you need
import { GoogleAuthProvider, getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAP5qTDTTWTIKJqYwj4wb0_WuV5T9EBHU0",
  authDomain: "dailysmirk.app",
  projectId: "dailysmirk-2ddba",
  storageBucket: "dailysmirk-2ddba.appspot.com",
  messagingSenderId: "345763913621",
  appId: "1:345763913621:web:5ce8190267a8b5c3f0609c",
  measurementId: "G-JD18X0JYD9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

export { auth, db, googleProvider };
