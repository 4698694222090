import React, { ReactNode, useContext, useState } from "react";

import { SmirkResponseType } from "../pages/eval/ResponseType";
import { Timestamp } from "firebase/firestore";

type EvalContextType = {
  loading: boolean;
  setLoading: (v: boolean) => void;
  evalDoc: EvalDoc | null;
  setEvalDoc: (v: EvalDoc | null) => void;
  targetDate: Date | null;
  setTargetDate: (v: Date | null) => void;
};

export type EvalDoc = {
  id: string;
  email: string;
  time: Timestamp;
  lastEdit?: Timestamp;
  response: SmirkResponseType[];
};

export const getStartOfDay = (day: Date) => {
  const target = new Date();
  target.setFullYear(day.getFullYear(), day.getMonth(), day.getDate());
  target.setHours(0, 0, 0, 0);
  return Timestamp.fromDate(target);
};

export const getStartOfNextDay = (day: Date) => {
  const target = new Date();
  target.setFullYear(day.getFullYear(), day.getMonth(), day.getDate());
  target.setHours(0, 0, 0, 0);
  target.setDate(target.getDate() + 1);
  return Timestamp.fromDate(target);
};

const EvalContext = React.createContext<EvalContextType>({
  loading: true,
  setLoading: () => {},
  evalDoc: null,
  setEvalDoc: () => {},
  targetDate: null,
  setTargetDate: () => {},
});

const EvalContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [loadingState, setLoadingState] = useState(false);
  const [evalDoc, setEvalDoc] = useState<EvalDoc | null>(null);
  const [targetDate, setTargetDate] = useState<Date | null>(null);

  const evalCx = {
    loading: loadingState,
    setLoading: setLoadingState,
    evalDoc,
    setEvalDoc,
    targetDate,
    setTargetDate,
  };
  return <EvalContext.Provider value={evalCx}>{children}</EvalContext.Provider>;
};

const useEvalContext = () => {
  const context = useContext(EvalContext);
  return context;
};

export { EvalContextProvider, useEvalContext };
