import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Timestamp, collection, onSnapshot } from "firebase/firestore";
import { auth, db } from "../utils/firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import { useProjectContext } from "./ProjectContext";

export type CalendarDoc = {
  email: string;
  rating: number;
  time: Timestamp;
  lastEdit?: Timestamp;
  collaboration?: string[];
  contributions?: string[];
  participation?: string[];
  comment?: string;
};

type CalendarCxType = {
  loading: boolean;
  data: CalendarDoc[];
};

const CalendarContext = React.createContext<CalendarCxType>({
  loading: true,
  data: [],
});

const CalendarContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<CalendarCxType>({ loading: true, data: [] });
  const [user, loading] = useAuthState(auth);
  const projectContext = useProjectContext();

  useEffect(() => {
    if (loading) return;
    if (!user) return;
    if (!projectContext) return;
    if (!projectContext.lastSelectedProject) return;
    console.log("fetch calendar");
    return onSnapshot(
      collection(
        db,
        `/prod/${projectContext.lastSelectedProject}/overview/${user.email}/calendar`
      ),
      (snapshot) => {
        setData({
          loading: false,
          data: snapshot.docs.map((d) => d.data() as CalendarDoc),
        });
      }
    );
  }, [loading, user, projectContext]);
  return (
    <CalendarContext.Provider value={data}>{children}</CalendarContext.Provider>
  );
};

const useCalendarContext = () => {
  const context = useContext(CalendarContext);
  return context;
};

export { CalendarContextProvider, useCalendarContext };
