import { SmirkResponseType } from "./ResponseType";
import { SurveyStep } from "./Survey";

type Props = {
  name: string;
  rating: number;
  step: SurveyStep;
  response: SmirkResponseType[];
  setResponse: (v: SmirkResponseType[]) => void;
};

const SurveyComment = ({ name, step, response, setResponse }: Props) => {
  const onUpdateComment = (comment: string) => {
    const newResponse = [...response];
    newResponse[step.mainStep].comment = comment;
    setResponse(newResponse);
  };

  return (
    <div className="space-y-1">
      <h2 className="font-bold underline">Additional Comments</h2>
      <div className="h-32 border-gray-500 px-1">
        <textarea
          value={response[step.mainStep].comment}
          onChange={(e) => onUpdateComment(e.target.value)}
          className="h-full w-full border-2 p-2"
          placeholder={`ex. Any other comments about ${name}`}
        />
      </div>
    </div>
  );
};

export default SurveyComment;
