import { HashRouter, Route, Routes } from "react-router-dom";
import {
  ProjectContextProvider,
  useProjectContext,
} from "./contexts/ProjectContext";

import Calendar from "./pages/app/Calendar";
import { CalendarContextProvider } from "./contexts/CalendarContext";
import Eval from "./pages/eval";
import { EvalContextProvider } from "./contexts/EvalContext";
import Guidance from "./pages/eval/Guidance";
import Home from "./pages/app/Home";
import Loading from "./pages/Loading";
import Main from "./pages/Main";
import { OverviewContextProvider } from "./contexts/OverviewContext";
import Profile from "./pages/app/Profile";
import SelectProject from "./pages/app/SelectProject";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Success from "./pages/Success";
import UnSupported from "./pages/app/UnSupported";
import { auth } from "./utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function App() {
  const [, loading] = useAuthState(auth);

  if (loading) return <Loading />;

  return (
    <ProjectContextProvider>
      <OverviewContextProvider>
        <EvalContextProvider>
          <CalendarContextProvider>
            <HashRouter>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/home" element={<Home />} />
                <Route path="/selectProject" element={<SelectProject />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/evaluation" element={<Eval />} />
                <Route path="/guidance" element={<Guidance />} />
                <Route path="/success" element={<Success />} />
                <Route path="/unsupported" element={<UnSupported />} />
              </Routes>
            </HashRouter>
          </CalendarContextProvider>
        </EvalContextProvider>
      </OverviewContextProvider>
    </ProjectContextProvider>
  );
}

export default App;
