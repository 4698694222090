import { Project, useProjectContext } from "../contexts/ProjectContext";

import { FaExchangeAlt } from "react-icons/fa";
import React from "react";
import Tabs from "./Tabs";
import { useNavigate } from "react-router-dom";

type LayoutProps = {
  title: string;
  currentTabIndex: number;
  showTab?: boolean;
  children?: React.ReactNode;
};

const getSelectedProjectName = (selectedId: string, projects: Project[]) => {
  const project = projects.find((p) => p.id === selectedId);
  return project ? project.projectName : "";
};

const Layout: React.FC<LayoutProps> = ({
  title,
  currentTabIndex,
  showTab = true,
  children,
}) => {
  const navigate = useNavigate();
  const projectContext = useProjectContext();
  if (!projectContext) return <></>;

  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="px-4 py-8 space-y-2">
        <h1 className="text-3xl font-bold">{title}</h1>
        <div
          className="flex flex-row items-center space-x-2 cursor-pointer"
          onClick={() => {
            navigate("/selectProject");
          }}
        >
          <h2 className="text-xl truncate">
            {getSelectedProjectName(
              projectContext.lastSelectedProject,
              projectContext.projects
            )}
          </h2>
          <FaExchangeAlt className="w-6" size={16} />
        </div>
      </div>
      {/* body */}
      <div className="grow">{children}</div>
      {/* Tab bar */}
      {showTab && <Tabs currentIndex={currentTabIndex} />}
    </div>
  );
};

export default Layout;
