import React from "react";

type Props = {
  disabled?: boolean;
  onNext: () => void;
};

const NextButton = ({ disabled = false, onNext }: Props) => {
  return disabled ? (
    <button className="border-2 w-full h-full border-gray-500 text-white bg-gray-500 py-4 px-4 rounded-xl">
      Next
    </button>
  ) : (
    <button
      className="border-2 w-full h-full border-blue-500 text-white bg-blue-500 py-4 px-4 rounded-xl"
      onClick={onNext}
    >
      Next
    </button>
  );
};

export default NextButton;
