import React, { ReactNode } from "react";

type EvalLayoutProps = {
  header: React.ReactNode;
  children: ReactNode;
};

const EvalLayout: React.FC<EvalLayoutProps> = ({ header, children }) => {
  return (
    <div className="h-full flex flex-col">
      {/* Header */}
      <div className="px-4 py-8 flex flex-row justify-between items-center">
        {header}
      </div>
      {/* body */}
      <div className="grow">{children}</div>
    </div>
  );
};

export default EvalLayout;
