import React from "react";
import { useLocation } from "react-router-dom";

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

// export const OVERVIEW_COLLECTION = `/prod/${designStudioId}/overview`;
