import React, { useEffect } from "react";

import { auth } from "../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

type Props = {};

const Success = (props: Props) => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return navigate("/");
  }, [user, navigate]);

  const onBack = () => {
    navigate("/home");
  };
  return (
    <div className="h-full w-full  flex justify-center items-center">
      <div className="space-y-8">
        <h1 className="text-4xl text-green-500">Success</h1>
        <p className="text-xl">Your response has been submitted</p>
        <button
          className="bg-blue-500 text-white w-full py-4 rounded-xl"
          onClick={onBack}
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default Success;
