import { FC, useEffect } from "react";

import EvalLayout from "../../components/EvalLayout";
import { FiArrowLeft } from "react-icons/fi";
import { Smirks } from "../../components/Smirks";
import { auth } from "../../utils/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

const Guidance: FC = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user === null) return navigate("/");
  }, [user, navigate]);

  return (
    <EvalLayout
      header={
        <>
          <FiArrowLeft
            className="text-3xl text-black mr-2"
            onClick={() => navigate("/evaluation")}
          />
          <h1 className="text-3xl font-bold grow">Guidance</h1>
        </>
      }
    >
      <div className="px-4 h-full flex flex-col">
        <div className="h-0 grow overflow-scroll space-y-4 pb-4">
          <Introduction />
          <SatisfactionLevel />
        </div>
        <div className="py-4">
          <button
            className="w-full bg-blue-500 text-white h-16 rounded-xl"
            onClick={() => navigate("/evaluation")}
          >
            Back to Rate My Peers
          </button>
        </div>
      </div>
    </EvalLayout>
  );
};

export default Guidance;

function SatisfactionLevel() {
  return (
    <div className="space-y-1">
      <div className="space-y-4">
        {Smirks.map((value, index) => (
          <div key={index} className="flex flex-row items-center space-x-2">
            <img className="w-10" src={value.file} alt={value.description} />
            <p style={{ color: value.color }} className="font-bold capitalize">
              {value.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

function Introduction() {
  return (
    <div className="space-y-1">
      {/* <h2 className="text-lg underline font-bold">Instructions</h2> */}
      <p className="indent-4">
        Use the smileys to rate each of your team members according to the
        legend below. Keep in mind that if you choose "
        <span
          style={{ color: Smirks[0].color }}
          className="font-bold capitalize"
        >
          {Smirks[0].description}
        </span>
        " or "
        <span
          style={{ color: Smirks[4].color }}
          className="font-bold capitalize"
        >
          {Smirks[4].description}
        </span>
        ", <span className="font-bold">Daily Smirk </span>
        will ask you to briefly explain.
      </p>
    </div>
  );
}
