import { FC } from "react";
import { ImCheckmark } from "react-icons/im";
import { MemberDoc } from "../../contexts/OverviewContext";
import { Smirks } from "../../components/Smirks";
import { User } from "firebase/auth";

type RateTableProps = {
  user: User | null | undefined;
  members: MemberDoc[];
  ratings: number[];
  setRatings: (v: number[]) => void;
};

const RateTable: FC<RateTableProps> = ({
  user,
  members,
  ratings,
  setRatings,
}) => {
  const onSetRating = (index: number, rating: number) => {
    return () => {
      const newRating = [...ratings];
      newRating[index] = rating;
      setRatings(newRating);
    };
  };

  const onSetAll = (rating: number) => {
    setRatings(members.map((v) => rating));
  };
  return (
    <>
      {/* display smirks */}
      <div className="flex flex-row">
        <div className="w-1/5" />
        <div className="grow flex flex-row justify-between">
          {Smirks.map((value, index) => (
            <div
              key={index}
              className="w-2/12 flex justify-center items-center"
            >
              <img
                className="w-12 cursor-pointer"
                src={value.file}
                alt={value.description}
                onClick={() => onSetAll(index)}
              />
            </div>
          ))}
        </div>
      </div>
      {/* selection for each member */}
      <div>
        {members
          .filter((x) => x.email !== user?.email)
          .map((value, index) => {
            return (
              <div
                key={index}
                className={
                  "flex flex-row py-3" + (index % 2 === 0 ? "" : " bg-blue-100")
                }
              >
                {MemberName(value)}
                {MemberRater(ratings, index, onSetRating)}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default RateTable;

function MemberRater(
  ratings: number[],
  index: number,
  onSetRating: (a: number, b: number) => () => void
) {
  return (
    <div className="grow flex flex-row justify-between">
      {Smirks.map((value, sindex) => {
        const isSelected = ratings[index] === sindex;
        return (
          <div
            key={sindex}
            className="w-2/12 flex justify-center items-center rounded-full"
          >
            <div
              className="w-7 h-7 rounded-full flex justify-center items-center cursor-pointer"
              style={{
                backgroundColor: value.color,
                opacity: isSelected ? 1.0 : 0.4,
              }}
              onClick={onSetRating(index, sindex)}
            >
              {isSelected && <ImCheckmark className="text-sm text-white" />}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function MemberName(value: MemberDoc) {
  return (
    <div className="w-1/5 pl-2">
      <p className="truncate">
        {value.displayName ? value.displayName : value.name}
      </p>
    </div>
  );
}
