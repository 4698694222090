import {
  EvalDoc,
  getStartOfDay,
  useEvalContext,
} from "../../contexts/EvalContext";
import { auth, db } from "../../utils/firebase";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import BarChart from "../../components/BarChart";
import { CalendarDoc } from "../../contexts/CalendarContext";
import Layout from "../../components/Layout";
import { Smirks } from "../../components/Smirks";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { useOverviewContext } from "../../contexts/OverviewContext";
import { useProjectContext } from "../../contexts/ProjectContext";

function Home() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const overviewContext = useOverviewContext();
  const evalCx = useEvalContext();
  const [calendarDocs, setCalendarDocs] = useState<CalendarDoc[]>([]);
  const projectContext = useProjectContext();

  useEffect(() => {
    if (user === null) return navigate("/");
  }, [user, navigate]);

  useEffect(() => {
    if (
      projectContext &&
      !projectContext.lastSelectedProject &&
      projectContext.projects.length > 0
    ) {
      navigate("/selectProject");
    } else if (projectContext?.projects.length === 0) {
      navigate("/unsupported");
    }
  });

  useEffect(() => {
    if (!projectContext) return;
    if (!projectContext.lastSelectedProject) return;
    // Load the latest evaluation
    const q = query(
      collection(
        db,
        `/prod/${projectContext.lastSelectedProject}/overview/${user?.email}/survey`
      ),
      where("time", ">=", getStartOfDay(new Date()))
    );
    evalCx.setLoading(true);
    const unsub = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.empty) {
        evalCx.setLoading(false);
        evalCx.setTargetDate(new Date());
        evalCx.setEvalDoc(null);
      } else {
        evalCx.setLoading(false);
        const evalDoc = querySnapshot.docs[0].data() as EvalDoc;
        evalDoc.id = querySnapshot.docs[0].id;
        evalCx.setTargetDate(evalDoc.time.toDate());
        evalCx.setEvalDoc(evalDoc);
      }
    });
    return unsub;
  }, [user?.email, projectContext]);

  useEffect(() => {
    if (!projectContext) return;
    if (!projectContext.lastSelectedProject) return;
    const fetchCalendarDocs = async () => {
      const q = query(
        collection(
          db,
          `/prod/${projectContext.lastSelectedProject}/overview/${user?.email}/calendar`
        ),
        orderBy("time", "desc")
      );

      return onSnapshot(q, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const docs = querySnapshot.docs
            .map((doc) => doc.data() as CalendarDoc)
            .filter((doc) => doc.comment);

          setCalendarDocs(docs.slice(0, 3));
        }
      });
    };
    fetchCalendarDocs();
  }, [user?.email, projectContext]);

  const onMainButton = () => {
    if (evalCx.evalDoc) {
      // edit mode
      navigate(`/evaluation?sid=${evalCx.evalDoc.id}`);
    } else {
      // create mode
      navigate("/evaluation");
    }
  };

  return (
    <Layout title={"Daily Smirk"} currentTabIndex={0}>
      <div className="h-full flex flex-col">
        <div className="flex flex-row justify-around pb-4 px-4">
          {Smirks.map((value, index) => {
            return (
              <img
                className="w-12"
                key={index}
                src={value.file}
                alt={value.description}
              />
            );
          })}
        </div>
        <div className="grow pt-4 pb-16 px-4">
          <BarChart smilies={overviewContext.smirks} />
        </div>
        <div className="px-4 pb-16 flex justify-center">
          <button
            className="bg-blue-500 shadow-2xl rounded-2xl text-white h-16 px-4 cursor-pointer w-2/3"
            onClick={onMainButton}
          >
            {evalCx.loading
              ? ""
              : evalCx.evalDoc
              ? "Change Today's Peer Ratings"
              : "Rate My Peers for Today"}
          </button>
        </div>
        <div className="px-5 pb-10 space-y-4">
          <div>
            <p className="font-bold text-2xl">Feedback given to you</p>
          </div>
          <div className="h-20 overflow-y-scroll">
            {calendarDocs.map(
              (value, index) =>
                value.comment && (
                  <div
                    key={index}
                    className="flex flex-row items-center space-x-2"
                  >
                    <div>
                      <img
                        className="w-12"
                        src={Smirks[value.rating].file}
                        alt={Smirks[value.rating].description}
                      />
                    </div>
                    <div>
                      <p>{value.comment}</p>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
