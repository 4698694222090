import { Link, useNavigate } from "react-router-dom";
import { auth, googleProvider } from "../../utils/firebase";
import {
  getRedirectResult,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from "firebase/auth";
import { useEffect, useState } from "react";

import { AiOutlineLoading } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { FirebaseError } from "firebase/app";
import { delay } from "../../utils";
import { useAuthState } from "react-firebase-hooks/auth";

function SignIn() {
  const [user, loading] = useAuthState(auth);

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pwdError, setPwdError] = useState("");
  const [signInError, setSignInError] = useState("");
  const [signing, setSigning] = useState(false);
  const navigate = useNavigate();

  const onSignIn = async () => {
    setSigning(true);

    setEmailError("");
    setPwdError("");
    setSignInError("");

    if (email === "") {
      setEmailError("Please enter an email");
    } else if (pwd === "") {
      setPwdError("Please enter a password!");
    } else if (pwd.length < 6) {
      setPwdError("Password is at least 6 characters");
    } else {
      await delay(500);
      try {
        await signInWithEmailAndPassword(auth, email.toLowerCase(), pwd);
        navigate("/home");
      } catch (e) {
        if (e instanceof FirebaseError) {
          console.log(e.code);
          if (e.code === "auth/invalid-email") {
            setEmailError("Email is invalid!");
          } else if (e.code === "auth/user-not-found") {
            setEmailError("User not found!");
          } else if (e.code === "auth/wrong-password") {
            setPwdError("Incorrect password!");
          } else if (e.code === "auth/too-many-requests") {
            setSignInError("Stop Spamming!");
          } else {
            setSignInError("Something went wrong!");
          }
        } else {
          setSignInError("Something went wrong!");
        }
      }
    }
    setSigning(false);
  };

  const onReset = () => {
    setEmailError("");
    if (email !== "") {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setEmailError("Reset email sent!");
        })
        .catch((e) => {
          if (e instanceof FirebaseError) {
            if (e.code === "auth/user-not-found") {
              setEmailError("Email not registered");
              return;
            }
          }
          setEmail("Something went wrong!");
        });
    } else {
      setEmailError("Please enter an email");
    }
  };

  const onSignInWithGoogle = async () => {
    signInWithRedirect(auth, googleProvider);
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    getRedirectResult(auth);
    if (user) {
      return navigate("/home");
    }
  });

  return (
    <div className="h-full flex justify-center items-center">
      <div className="border-2 p-4 w-full m-4">
        <div className="mb-4">
          <h1 className="text-3xl">Sign In</h1>
        </div>
        <div className="space-y-4">
          <div>
            <div className="flex flex-col">
              <label>Email</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-2 border-blue-500 p-2 rounded"
              />
              {emailError !== "" ? (
                <p className="text-red-500">{emailError}</p>
              ) : (
                <p className="text-red-500 invisible">Email error</p>
              )}
            </div>
            <div className="flex flex-col">
              <label>Password</label>
              <input
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                className="border-2 border-blue-500 p-2 rounded"
                type="password"
              />
              {pwdError !== "" ? (
                <p className="text-red-500">{pwdError}</p>
              ) : (
                <p className="text-red-500 invisible">Password error</p>
              )}
            </div>
          </div>
          <div>
            {signing ? (
              <button className="w-full bg-gray-500 text-white p-4 flex justify-center items-center h-16">
                <AiOutlineLoading className="animate-spin text-xl" />
              </button>
            ) : (
              <button
                className="w-full bg-blue-500 text-white p-4 h-16"
                onClick={onSignIn}
              >
                Sign In
              </button>
            )}
            <div className="w-full flex flex-row justify-center items-center space-x-2">
              <button className=" p-4 h-16" onClick={onSignInWithGoogle}>
                <p className="flex justify-center items-center">
                  <FcGoogle className="text-blue-500" />
                  <span className="ml-2" />
                  Continue with Google
                </p>
              </button>
            </div>
            {signInError !== "" ? (
              <p className="text-red-500">{signInError}</p>
            ) : (
              <p className="text-red-500 invisible">Sign In error</p>
            )}
          </div>
          <div className="flex flex-row justify-between">
            <Link to="/" className="text-blue-500 underline">
              Back to main page
            </Link>
            <p
              className="text-blue-500 underline cursor-pointer"
              onClick={onReset}
            >
              Reset Password
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
