import { ResponseOptions, SmirkResponseType } from "./ResponseType";
import { useEffect, useState } from "react";

import { ImCheckmark } from "react-icons/im";
import { SurveyStep } from "./Survey";

type Props = {
  name: string;
  rating: number;
  step: SurveyStep;
  response: SmirkResponseType[];
  setResponse: (v: SmirkResponseType[]) => void;
};

const SurveyOptions = ({
  name,
  rating,
  step,
  response,
  setResponse,
}: Props) => {
  const questions =
    rating === 0 ? ResponseOptions.satisfied : ResponseOptions.dissatisfied;
  const { subStep } = step;

  const options =
    subStep === 1
      ? questions.participation
      : subStep === 2
      ? questions.collaboration
      : questions.contributions;

  const subTitle =
    subStep === 1
      ? "Participation"
      : subStep === 2
      ? "Collaboration"
      : "Contribution";

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    switch (step.subStep) {
      case 1:
        setSelectedOptions(response[step.mainStep].participation);
        return;
      case 2:
        setSelectedOptions(response[step.mainStep].collaboration);
        return;
      case 3:
        setSelectedOptions(response[step.mainStep].contributions);
        return;
    }
  }, [response, step, setSelectedOptions]);

  const onSelectOption = (step: SurveyStep, value: string) => {
    const newResponse = [...response];

    if (step.subStep === 1) {
      const vindex = newResponse[step.mainStep].participation.indexOf(value);
      vindex === -1
        ? newResponse[step.mainStep].participation.push(value)
        : newResponse[step.mainStep].participation.splice(vindex, 1);
    } else if (step.subStep === 2) {
      const vindex = newResponse[step.mainStep].collaboration.indexOf(value);
      vindex === -1
        ? newResponse[step.mainStep].collaboration.push(value)
        : newResponse[step.mainStep].collaboration.splice(vindex, 1);
    } else if (step.subStep === 3) {
      const vindex = newResponse[step.mainStep].contributions.indexOf(value);
      vindex === -1
        ? newResponse[step.mainStep].contributions.push(value)
        : newResponse[step.mainStep].contributions.splice(vindex, 1);
    }
    setResponse(newResponse);
  };

  return (
    <div className="space-y-1">
      <h2 className="font-bold underline">
        {subTitle} ({name})
      </h2>
      {options.map((v, i) => {
        return (
          <div
            key={i}
            className="flex flex-row items-start space-x-2 cursor-pointer"
            onClick={() => onSelectOption(step, v)}
          >
            <div className="mt-1">
              {selectedOptions.includes(v) ? (
                <div className="w-6 h-6 border-2 border-blue-500 flex justify-center items-center">
                  <ImCheckmark className=" text-4xl text-blue-500" />
                </div>
              ) : (
                <div className="w-6 h-6 border-2 border-gray-500 flex justify-center items-center">
                  <ImCheckmark className="text-4xl text-blue-500 invisible" />
                </div>
              )}
            </div>
            <p>{v}</p>
          </div>
        );
      })}
    </div>
  );
};

export default SurveyOptions;
