import React, { ReactNode, useState } from "react";
import { auth, db } from "../utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";

export type Project = {
  id: string;
  projectName: string;
  courseName: string;
  color: string;
  isActive: boolean;
};

export type ProjectContextType = {
  lastSelectedProject: string;
  projects: Project[];
};

const ProjectContext = React.createContext<ProjectContextType | null>(null);

const ProjectContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user] = useAuthState(auth);
  const [participatingTeams, setParticipatingTeams] =
    React.useState<ProjectContextType | null>(null);

  React.useEffect(() => {
    if (user && user.email) {
      const docRef = doc(
        db,
        `/prod/_projects/participating_teams/${user.email}`
      );
      const unsub = onSnapshot(docRef, (doc) => {
        if (doc.data()) {
          setParticipatingTeams(doc.data() as ProjectContextType);
        } else {
          setParticipatingTeams({ lastSelectedProject: "", projects: [] });
        }
      });
      return unsub;
    }
  }, [user]);

  return (
    <ProjectContext.Provider value={participatingTeams}>
      {children}
    </ProjectContext.Provider>
  );
};

const useProjectContext = () => {
  const context = React.useContext(ProjectContext);
  if (context === undefined) {
    console.log(context);
    throw new Error("useProjectContext must be used within a ProjectProvider");
  }
  return context;
};

export { ProjectContextProvider, useProjectContext };
