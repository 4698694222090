import React, { useRef, useState } from "react";
import {
  SmirkResponseType,
  checkResponseSubmittable,
  hasAtLeastOneOption,
  requireMoreInformation,
  submitResponse,
} from "./ResponseType";

import EvalLayout from "../../components/EvalLayout";
import NextButton from "../../components/NextButton";
import { Smirks } from "../../components/Smirks";
import SubmitButton from "../../components/SubmitButton";
import SurveyComment from "./SurveyComment";
import SurveyIntro from "./SurveyIntro";
import SurveyOptions from "./SurveyOptions";
import { auth } from "../../utils/firebase";
import { delay } from "../../utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEvalContext } from "../../contexts/EvalContext";
import { useNavigate } from "react-router-dom";
import { useOverviewContext } from "../../contexts/OverviewContext";
import { useProjectContext } from "../../contexts/ProjectContext";

export type SurveyStep = {
  mainStep: number;
  subStep: number;
};

type Props = {
  response: SmirkResponseType[];
  setResponse: (v: SmirkResponseType[]) => void;
  exitSurvey: () => void;
  optionalComments: boolean;
};

function Survey({
  response,
  setResponse,
  exitSurvey,
  optionalComments,
}: Props) {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const evalCx = useEvalContext();
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const overviewContext = useOverviewContext();
  const projectContext = useProjectContext();

  const steps: number[] = [];
  let totalStep = 0;
  response.forEach((v, i) => {
    if (requireMoreInformation(v)) {
      steps.push(i);
      totalStep += 1;
    }
  });
  if (optionalComments) {
    steps.push(-1);
    totalStep += 1;
  }

  const onBack = (e: React.MouseEvent<HTMLElement>) => {
    if (currentStep === 0) {
      exitSurvey();
    } else {
      setCurrentStep(currentStep - 1);
    }
    contentRef?.current?.scrollTo({ top: 0 });
  };

  const onNext = () => {
    if (currentStep >= totalStep - 1) {
      setCurrentStep(totalStep - 1);
    } else {
      setCurrentStep(currentStep + 1);
    }
    contentRef?.current?.scrollTo({ top: 0 });
  };

  const onSubmit = async () => {
    if (!projectContext) return;
    const submittable = checkResponseSubmittable(response);
    if (submittable === "") {
      setIsSubmitting(true);
      await delay(500);
      submitResponse(
        evalCx.evalDoc?.id ? evalCx.evalDoc.id : "",
        user?.email!,
        projectContext.lastSelectedProject,
        overviewContext.team?.teamName!,
        response,
        evalCx.targetDate!
      )
        .then(() => {
          navigate("/success");
          setIsSubmitting(false);
        })
        .catch((e) => {
          // error occurred
          setIsSubmitting(false);
        });
    } else {
      console.log("need more information");
    }
  };

  return (
    <EvalLayout
      header={
        <>
          {steps[currentStep] !== -1 && (
            <div className="flex justify-center items-center space-x-2">
              <div className="h-12 w-12">
                <img
                  className="h-full w-full"
                  src={Smirks[response[steps[currentStep]].rating].file}
                  alt={Smirks[response[steps[currentStep]].rating].description}
                />
              </div>
              <div className="w-48">
                <h2 className="text-2xl font-bold truncate">
                  {response[steps[currentStep]].name}
                </h2>
              </div>
            </div>
          )}
        </>
      }
    >
      <div className="h-full w-full px-4 flex flex-col">
        {/* main components */}
        {steps[currentStep] !== -1 && (
          <div className="grow h-0 overflow-scroll space-y-4" ref={contentRef}>
            <SurveyIntro
              name={response[steps[currentStep]].name}
              rating={response[steps[currentStep]].rating}
            />
            {[1, 2, 3].map((v, i) => (
              <SurveyOptions
                name={response[steps[currentStep]].name}
                rating={response[steps[currentStep]].rating}
                step={{ mainStep: steps[currentStep], subStep: v }}
                response={response}
                setResponse={setResponse}
                key={i}
              />
            ))}
            <SurveyComment
              name={response[steps[currentStep]].name}
              rating={response[steps[currentStep]].rating}
              step={{ mainStep: steps[currentStep], subStep: 4 }}
              response={response}
              setResponse={setResponse}
            />
          </div>
        )}
        {/* optional comments page */}
        {steps[currentStep] === -1 && (
          <div className="grow h-0 overflow-scroll space-y-4">
            {response.map((v, i) => (
              <>
                {[1, 2, 3].includes(v.rating) && (
                  <div>
                    <div className="flex items-center space-x-2">
                      <div className="h-12 w-12">
                        <img
                          className="h-full w-full"
                          src={Smirks[v.rating].file}
                          alt={Smirks[v.rating].description}
                        />
                      </div>
                      <div className="w-48">
                        <h2 className="text-2xl font-bold truncate">
                          {v.name}
                        </h2>
                      </div>
                    </div>
                    <SurveyComment
                      name={v.name}
                      rating={v.rating}
                      step={{ mainStep: i, subStep: 4 }}
                      response={response}
                      setResponse={setResponse}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        )}

        {/* actions */}
        <div className="w-full flex flex-row py-4 h-24 justify-between items-center">
          <button
            className="border-2 w-1/3 h-full border-blue-500 text-blue-500 bg-white py-4 px-4 rounded-xl"
            onClick={onBack}
          >
            Back
          </button>
          <p className="font-mono w-1/4 text-center">
            {currentStep + 1}/{totalStep}
          </p>
          <div className="h-full w-1/3">
            {currentStep === totalStep - 1 ? (
              <SubmitButton
                loading={isSubmitting}
                disabled={checkResponseSubmittable(response) !== ""}
                onClick={onSubmit}
              />
            ) : (
              <NextButton
                onNext={onNext}
                disabled={!hasAtLeastOneOption(response[steps[currentStep]])}
              />
            )}
          </div>
        </div>
      </div>
    </EvalLayout>
  );
}

export default Survey;
