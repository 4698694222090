import { AiOutlineLoading3Quarters } from "react-icons/ai";

type Props = {
  loading: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const SubmitButton = ({ loading, disabled = false, onClick }: Props) => {
  const className = disabled
    ? "border-2 w-full h-full border-gray-500 text-white bg-gray-500 py-4 px-4 rounded-xl flex justify-center items-center space-x-2"
    : loading
    ? "border-2 w-full h-full border-gray-500 text-white bg-gray-500 py-4 px-4 rounded-xl flex justify-center items-center space-x-2"
    : "border-2 w-full h-full border-blue-500 text-white bg-blue-500 py-4 px-4 rounded-xl flex justify-center items-center space-x-2";

  const handleOnClick = () => {
    if (!disabled && !loading) {
      onClick();
    }
  };

  return (
    <button className={className} onClick={handleOnClick}>
      {loading ? (
        <AiOutlineLoading3Quarters className="animate-spin" />
      ) : (
        <p>Submit</p>
      )}
    </button>
  );
};

export default SubmitButton;
